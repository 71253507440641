 
 <template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-row>
      <v-col class="mt-6 pb-0" cols="12" v-if="isAdmin">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-btn @click="deleteEvent">Delete</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6" class="pr-0">
        <v-card>
          <v-card-text v-if="isAdmin">
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1 mb-2">Details</div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.title"
                  label="Event title"
                  v-validate="'required'"
                  data-vv-as="title"
                  name="title"
                  :error-messages="errors.collect('title')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="vm.isReccuring"
                  label="Recurring event"
                  @change="recurringChange"
                ></v-switch>
              </v-col>

              <v-col cols="6" v-if="!vm.isReccuring">
                <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.startDateString"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.startDateString"
                      label="Start date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-as="startDateString"
                      name="startDateString"
                      :error-messages="errors.collect('startDateString')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.startDateString"
                    no-title
                    scrollable
                    color="green lighten-1"
                    dark
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.startDateMenu.save(vm.startDateString)"
                    >OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" v-if="!vm.isReccuring">
                <v-menu
                  ref="startTimeMenu"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.startTime"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.startTimeString"
                      label="Start time"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-validate="'required'"
                      data-vv-as="startTimeString"
                      name="startTimeString"
                      :error-messages="errors.collect('startTimeString')"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                   format="24hr"
                   :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
                    v-if="startTimeMenu"
                    v-model="vm.startTimeString"
                    full-width
                    @click:minute="$refs.startTimeMenu.save(vm.startTimeString)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" v-if="!vm.isReccuring">
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.endDate"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.endDateString"
                      label="End date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-as="endDateString"
                      name="endDateString"
                      :error-messages="errors.collect('endDateString')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.endDateString"
                    no-title
                    scrollable
                    color="green lighten-1"
                    dark
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.endDateMenu.save(vm.endDateString)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" v-if="!vm.isReccuring">
                <v-menu
                  ref="endTimeMenu"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.endTime"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.endTimeString"
                      label="End time"
                      prepend-icon="mdi-clock-outline"
                      v-validate="'required'"
                      data-vv-as="endTimeString"
                      name="endTimeString"
                      :error-messages="errors.collect('endTimeString')"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                   format="24hr"
                   :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
                    v-if="endTimeMenu"
                    v-model="vm.endTimeString"
                    full-width
                    @click:minute="$refs.endTimeMenu.save(vm.endTimeString)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" v-if="vm.isReccuring==true">
                <v-menu
                  ref="startRecurMenu"
                  v-model="startRecurMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.startRecurString"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.startRecurString"
                      label="Recur from"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-as="startRecurString"
                      name="startRecurString"
                      :error-messages="errors.collect('startRecurString')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.startRecurString"
                    no-title
                    scrollable
                    color="green lighten-1"
                    dark
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startRecurMenu = false">Cancel</v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startRecurMenu.save(vm.startRecurString)"
                    >OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" v-if="vm.isReccuring==true">
                <v-menu
                  ref="endRecurMenu"
                  v-model="endRecurMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.endRecurString"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.endRecurString"
                      label="Recur to"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-as="endRecurString"
                      name="endRecurString"
                      :error-messages="errors.collect('endRecurString')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vm.endRecurString"
                    no-title
                    scrollable
                    color="green lighten-1"
                    dark
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endRecurMenu = false">Cancel</v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endRecurMenu.save(vm.endRecurString)"
                    >OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="mt-2 mb-4" cols="12" v-if="vm.isReccuring">
                <v-btn-toggle v-model="vm.daysOfWeek" multiple>
                  <v-btn>Su</v-btn>
                  <v-btn>Mo</v-btn>
                  <v-btn>Tu</v-btn>
                  <v-btn>We</v-btn>
                  <v-btn>Th</v-btn>
                  <v-btn>Fr</v-btn>
                  <v-btn>Sa</v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="6" v-if="vm.isReccuring">
                <v-menu
                  ref="startTimeMenu"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.startTime"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.startTimeString"
                      label="Start time"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-validate="'required'"
                      data-vv-as="startTimeString"
                      name="startTimeString"
                      :error-messages="errors.collect('startTimeString')"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                   format="24hr"
                   :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
                    v-if="startTimeMenu"
                    v-model="vm.startTimeString"
                    full-width
                    @click:minute="$refs.startTimeMenu.save(vm.startTimeString)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" v-if="vm.isReccuring">
                <v-menu
                  ref="endTimeMenu"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="vm.endTimeString"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vm.endTimeString"
                      label="End time"
                      prepend-icon="mdi-clock-outline"
                      v-validate="'required'"
                      data-vv-as="endTimeString"
                      name="endTimeString"
                      :error-messages="errors.collect('endTimeString')"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                   format="24hr"
                   :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
                    v-if="endTimeMenu"
                    v-model="vm.endTimeString"
                    full-width
                    @click:minute="$refs.endTimeMenu.save(vm.endTimeString)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <address-picker
                  :detail-mode="true"
                  label="Location"
                  :min-characters="4"
                  v-model="vm.location"
                ></address-picker>
              </v-col>
             <v-col cols="12">
            <v-select
              item-text="name"
              v-model="supportCategory"
              :items="lineItemDefinition.supportCategories"
              label="Support Category"
              return-object
            ></v-select>
          </v-col>
              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="vm.notes"
                  label="Notes"
                  v-validate="''"
                  rows="3"
                  data-vv-as="Notes"
                  name="notes"
                  :error-messages="errors.collect('notes')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="!isAdmin">
           <v-row>
              <v-col cols="12">
                <div class="subtitle-1 mb-2">Details</div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.title"
                  label="Event title"
                  v-validate="'required'"
                  readonly
                  data-vv-as="title"
                  name="title"
                  :error-messages="errors.collect('title')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span style="font-size: 11;">Start time: {{ vm.startTime }}</span>&nbsp;&nbsp;&nbsp;<span>End time: {{ vm.endTime }}</span>
              </v-col>
               <v-col cols="12">
                <v-textarea
                  filled
                  v-model="vm.notes"
                  label="Notes"
                  v-validate="''"
                  readonly
                  rows="3"
                  data-vv-as="Notes"
                  name="notes"
                  :error-messages="errors.collect('notes')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="isAdmin">
        <v-card>
          <v-card-text>
            <v-row class="mt-0">
              <v-col cols="12">
                <div class="subtitle-1 mb-2">Guests</div>
              </v-col>
              <v-col cols="12">
                <guest-picker
                  :min-characters="4"
                  v-model="vm.guestSearch"
                  v-on:guest-selected="addGuest"
                ></guest-picker>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item v-for="(guest,index) in vm.guests" :key="guest.id">
                    <v-list-item-avatar>
                      <v-badge class="align-self-center" bottom overlap color="transparent">
                        <template v-slot:badge>
                          <v-icon color="blue" v-if="guest.type=='Participant'">mdi-account</v-icon>
                          <v-icon color="green " v-if="guest.type=='Staff'">mdi-account-tie</v-icon>
                          <v-icon v-if="guest.type=='Other'">mdi-account-question</v-icon>
                        </template>
                        <img :src="guest.avatar" v-if="guest.avatar" style="height:40px" />
                        <v-icon v-if="!guest.avatar || guest.avatar==null">mdi-account</v-icon>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{guest.name?guest.name:guest.email}} ({{guest.type}})</v-list-item-title>
                      <v-list-item-subtitle v-if="guest.name">{{guest.email}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="removeGuest(index)">mdi-trash-can-outline</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                Background color: &nbsp;
                <swatches
                  shapes="circles"
                  :show-border="true"
                  swatch-size="30"
                  v-model="backgroundColor"
                  colors="material-basic"
                  popover-to="left"
                ></swatches>
              </v-col>
              <v-col cols="6">
                Text color: &nbsp;
                <swatches
                  shapes="circles"
                  :show-border="true"
                  swatch-size="30"
                  v-model="textColor"
                  colors="text-basic"
                  popover-to="left"
                ></swatches>
              </v-col>
              <v-col cols="12">
                <div :style="eventStyle" class="dummyEvent">
                  {{vm.startTimeString}} - {{ vm.endTimeString}}
                  <br />
                  {{vm.title}}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
import AddressPicker from "../../components/core/AddressPicker";
import * as eventService from "../../services/eventService";
//import * as refDataService from "../../services/referenceDataService";
import * as lineItemService from "../../services/lineItemSevice";
import GuestPicker from "./guest-picker";
//import Datepicker from "vuejs-datepicker";

import Swatches from "vue-swatches";

// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.min.css";

import moment from "moment";
export default {
  name: "event",
  components: {
    AddressPicker,
    //  Datepicker,
    Swatches,
    GuestPicker
  },
  data: () => ({
    vm: {},
    datetime: null,
    eventStyle: "",
    backgroundColor: "#3398db",
    textColor: "#FFFFFF",
    valid: true,
    isAdmin: false,
    programs: [],
    lineItemDefinition: {},
    startTimeMenu: false,
    startDateMenu: false,
    endTimeMenu: false,
    endDateMenu: false,
    startRecurMenu: false,
    endRecurMenu: false,
    text: "center",
    icon: "justify",
    toggle_none: null,
    toggle_one: 0,
    toggle_exclusive: 2
  }),

  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentEvent() {
      return this.$store.getters.currentEvent;
    },

    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    backgroundColor(val) {
      this.eventStyle = `background-color:${val}; color:${this.textColor}`;
    },
    textColor(val) {
      this.eventStyle = `background-color:${this.backgroundColor}; color:${val}`;
    }
  },
  methods: {
    setText(item) {
      console.log(item);
      return item.name + "(" + item.type + ")";
    },
    init() {
      let me = this;
      this.vm = { guests: [], isReccuring: false };

      if (this.currentEvent !== undefined && this.currentEvent !== null) {
        this.vm = this.currentEvent;

        me.backgroundColor = me.vm.backgroundColor;
        me.textColor = me.vm.textColor;
      }

      if (this.vm.guests.length == 0) {
        this.vm.guests.push({
          name: this.user.firstName + " " + this.user.lastName,
          email: this.user.email,
          type: "Staff",
          id: this.user.id,
          avatar: this.user.photoURL
        });
      }
      this.eventStyle = `background-color:${this.backgroundColor}; color:${this.textColor}`;
      /*
      refDataService
        .listByKey(me.currentOrganisation.id, "programs")
        .then(results => {
          results.docs.map(doc => {
            let item = doc.data();
            item.id = doc.id;
            me.programs.push(item);
          });
        });
      */

     this.lineItemDefinition = {};
      lineItemService
        .list()
        .then(result => {
          result.docs.map(doc => {
            const lid = doc.data();
            const dt = new Date();
            const from = new Date(lid.effectiveFromDate.replace(/-/g, "/"));
            const to = new Date(lid.effectiveToDate.replace(/-/g, "/"));
            if(dt>=from && dt<=to){
              this.lineItemDefinition = lid;
              this.lineItemDefinition.id = doc.id;
              console.log(this.lineItemDefinition.supportCategories);
            }
          });
        });
      if (
      this.user.roles.indexOf("System Administrator") > -1 ||
      this.user.roles.indexOf("Organisation Administrator") > -1 ||
      this.user.roles.indexOf("Billing") > -1 )
       {
      this.isAdmin = true;
      
      }
    },
    deleteEvent() {
      const me = this;
      if (confirm("Are you sure you want to delete this event?")) {
        eventService
          .deleteById(me.currentOrganisation.id, me.currentEvent.id)
          .then(function() {
            me.$store.commit("setCurrentEvent", null);
            me.$store.dispatch("setToast", {
              message: "Event deleted",
              color: "success"
            });
            me.$router.push("/roster");
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    recurringChange(isRecurring) {
      console.log(isRecurring);
    },
    removeGuest(index) {
      this.vm.guests.splice(index, 1);
      this.$forceUpdate();
    },

    addGuest(guest) {
      if (this.vm.guests == undefined) {
        this.vm.guests = [];
      }
      const match = this.vm.guests.find(x => {
        return x.id === guest.id;
      });
      if (match == null) {
        this.vm.guests.push(guest);
      }
      this.$forceUpdate();
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let me = this;

          if (me.vm.isReccuring) {
            me.vm.startRecurDate = new Date(
              me.vm.startRecurString + " " + me.vm.startTimeString
            );
            me.vm.endRecurDate = new Date(
              me.vm.endRecurString + " " + me.vm.endTimeString
            );
            me.vm.startTime = me.vm.startTimeString;
            me.vm.endTime = me.vm.endTimeString;
            //used for backend searching
            me.vm.startDate = new Date(
              me.vm.startRecurString + " " + me.vm.startTimeString
            );
            me.vm.endDate = new Date(
              me.vm.endRecurString + " " + me.vm.endTimeString
            );
            me.vm.start = me.vm.startDate;
            me.vm.end = me.vm.endDate;
          } else {
            me.vm.startRecur = null;
            me.vm.endRecur = null;
            me.vm.startTime = null;
            me.vm.endTime = null;
            me.vm.daysOfWeek = null;

            //used for backend searching
            me.vm.startDate = new Date(
              me.vm.startDateString + " " + me.vm.startTimeString
            );
            me.vm.start = me.vm.startDate;
            me.vm.endDate = new Date(
              me.vm.endDateString + " " + me.vm.endTimeString
            );
            me.vm.end = me.vm.endDate;
          }

          me.vm.isMultiDayEvent =
            moment(me.vm.startDate).dayOfYear() !=
            moment(me.vm.endDate).dayOfYear();
          me.vm.backgroundColor = me.backgroundColor;
          me.vm.textColor = me.textColor;

          me.vm.staffIds = [];
          me.vm.guests.forEach(guest => {
            if (guest.type === "Staff") {
              me.vm.staffIds.push(guest.id);
            }
          });
          eventService
            .save(me.currentOrganisation.id, me.vm)
            .then(ref => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              me.$store.dispatch("setToast", {
                message: "Event saved",
                color: "primary"
              });
              this.$router.push("/roster");
            })
            .catch(error => {
              console.error("Error saving event: ", error);
            });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    updateStartDatetime: function(datetime) {
      this.vm.startDateTime = datetime;
    },
    updateEndDatetime: function(datetime) {
      this.vm.endDateTime = datetime;
    },
    close() {
      this.$router.push("/roster");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.v-btn-toggle .v-btn--active {
  background-color: #4caf50 !important;
  color: #fff !important;
}
.vue-swatches {
  display: inline-block;
  position: relative;
  top: 8px;
}
.vue-swatches__trigger {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 30px;
  max-width: 30px;
}
span {
  font-size: 14px;
}
.dummyEvent {
  padding: 5px;
  height: 50px;
  margin-top: 20px;
  display: inline-block;
  max-width: 200px;
  min-width: 120px;
  font-size: 12px;
}
</style>

